import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import jwtDecode from "jwt-decode";
import Admin from "./components/Admin";
import Shop from "./components/Shop";
import Photos from "./components/Photos";
import NotFound from "./components/NotFound";
import NavBar from "./components/Navbar";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Logout from "./components/Logout";
import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";

function App(props) {
  const [user, setUser] = useState({});
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    try {
      const jwt = localStorage.getItem("token");
      const user = jwtDecode(jwt);
      setUser(user);
    } catch (ex) {
      setUser({});
    }
  }, [loggedIn]);

  return (
    <React.Fragment>
      <ToastContainer />
      <Router>
        <NavBar loggedIn={loggedIn} />
        <main role="main">
          <Switch>
            <Route path="/not-found" component={NotFound} />
            <Route
              path="/admin"
              render={(props) =>
                user.isAdmin ? <Admin /> : <Redirect to="/login" />
              }
            />
            <Route
              path="/login"
              render={(props) => <Login {...props} setLoggedIn={setLoggedIn} />}
            />
            <Route
              path="/logout"
              render={(props) => (
                <Logout {...props} setLoggedIn={setLoggedIn} />
              )}
            />
            <Route path="/shop" component={Shop} />
            <Route
              path="/people"
              render={(props) => <Photos {...props} gallery="people" />}
            />
            <Route
              path="/landscapes"
              render={(props) => <Photos {...props} gallery="landscapes" />}
            />
            <Route path="/contact" component={Contact} />
            <Redirect exact from="/" to="/people" />
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </Router>
    </React.Fragment>
  );
}

export default App;
