import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import config from "../config";

function Login({ setLoggedIn, history }) {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    localStorage.removeItem("token");
    setLoggedIn(false);
  }, [setLoggedIn]);

  const handleLogin = async (e) => {
    e.preventDefault();
    // console.log("Logging In:", username, password);
    const { data: jwt } = await axios.post(`http://${config.API_URL}/login`, {
      email: username,
      password: password,
    });
    if (jwt === "USER Invalid") {
      toast.error("Invalid Email");
    } else if (jwt === "AUTH Invalid") {
      toast.error("Invalid Password!");
    } else {
      toast.success("Logged In!");
      // console.log(jwt);
      localStorage.setItem("token", jwt);
      setLoggedIn(true);
      setUsername("");
      setPassword("");
      history.push("/admin");
    }
  };

  return (
    <div className="container" style={{ maxWidth: "400px" }}>
      <form onSubmit={(e) => handleLogin(e)}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            required=" "
            type="email"
            className="form-control"
            id="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password:</label>
          <input
            required=" "
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
