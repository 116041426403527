import React from "react";
import config from "../config";
import { useSpring, animated } from "react-spring";

function AdminPhoto({ image, category, handleDelete, del }) {
  const animation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 100,
    config: { duration: 1000 },
  });
  return (
    <animated.div style={animation}>
      <div className="img-wrap">
        <button className="close" onClick={() => handleDelete(image)}>
          &times;
        </button>
        <img
          src={`http://${config.PHOTO_URL}/static/${category}/${image.name}`}
          alt={`slide-${image.name}`}
          style={{
            objectFit: "contain",
            height: `200px`,
            margin: "5px",
            opacity: del === image.name ? ".3" : "1",
            transition: "all 0.3s linear",
          }}
        />
      </div>
    </animated.div>
  );
}

export default AdminPhoto;
