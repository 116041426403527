import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function NavBar({ loggedIn }) {
  return (
    <Navbar
      collapseOnSelect
      expand="md"
      bg="light"
      variant="light"
      style={{ marginBottom: " 20px" }}
    >
      <Navbar.Brand>Kristoff Lutchman</Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          <NavLink className="nav-link" to="/people">
            People
          </NavLink>
          <NavLink className="nav-link" to="/landscapes">
            Landscapes
          </NavLink>
          <NavLink className="nav-link" to="/contact">
            Contact
          </NavLink>
          {/* <NavLink className="nav-link" to="/shop">
            Shop
          </NavLink> */}
          {loggedIn && (
            <React.Fragment>
              <NavLink className="nav-link" to="/admin">
                Admin
              </NavLink>
              <NavLink className="nav-link" to="/logout">
                Logout
              </NavLink>
            </React.Fragment>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default NavBar;
