import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialIcon({ url, icon }) {
  return (
    <li>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "black" }}
      >
        <FontAwesomeIcon icon={["fab", icon]} size="3x" />
      </a>
    </li>
  );
}

export default SocialIcon;
