import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import axios from "axios";
import config from "../config";

function UploadForm({ category, file, setFile }) {
  const [filename, setFilename] = useState("");
  const [busy, setBusy] = useState(false);

  const handleUpload = async (e) => {
    setBusy(true);
    e.preventDefault();
    if (file !== "") {
      // console.log("Uploading: ", file);
      const formData = new FormData();
      formData.append("file", file);
      await axios.post(`http://${config.API_URL}/upload/${category}`, formData);
      setFile(null);
    }
    setBusy(false);
  };

  useEffect(() => {
    file ? setFilename(file.name) : setFilename("");
  }, [file]);

  return (
    <div>
      <form
        onSubmit={(e) => handleUpload(e)}
        method="POST"
        encType="multipart/form-data"
      >
        <input
          type="file"
          className="custom-file-input"
          id="customFile"
          accept=".jpg, .jpeg"
          onChange={(e) => setFile(e.target.files[0])}
        />

        <label className="custom-file-label" htmlFor="customFile">
          {filename}
        </label>

        <button
          type="submit"
          disabled={filename === "" ? true : false}
          className="btn btn-secondary btn-lg btn-block"
          style={{ marginTop: "10px" }}
        >
          {busy ? (
            <Spinner animation="border" variant="light" size="sm" />
          ) : (
            "Upload"
          )}
        </button>
      </form>
    </div>
  );
}

export default UploadForm;
