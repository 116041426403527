import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SlideButton({ position, height, onClick }) {
  return (
    <button
      onClick={() => onClick(position)}
      id={`go-${position}`}
      style={{ height: `${height - 100}px` }}
    >
      <FontAwesomeIcon icon={["fas", `chevron-${position}`]} size="2x" />
    </button>
  );
}

export default SlideButton;
