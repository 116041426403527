import React from "react";
import config from "../config";
import { useSpring, animated } from "react-spring";

function Photo({
  image,
  onClick,
  activeSlide,
  height,
  width,
  offset,
  gallery,
  mobile,
}) {
  const animation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    delay: 100,
    config: { duration: 750 },
  });
  return (
    <animated.div style={animation}>
      <img
        src={`http://${config.PHOTO_URL}/static/${gallery}/${image.name}`}
        alt={`slide${image.name}`}
        className={
          image.name === activeSlide
            ? "slider active-slide photo"
            : "slider photo"
        }
        onClick={() => onClick(image.name)}
        style={{
          objectFit: "contain",
          height: mobile ? "" : `${height - offset}px`,
          width: mobile ? `${width * 0.98}px` : "",
        }}
      />
    </animated.div>
  );
}

export default Photo;
