import React, { useState, useEffect } from "react";
import axios from "axios";
import Photo from "./Photo";
import SlideButton from "./SlideButton";
import config from "../config";

function useImageData(path) {
  const [imageData, setImageData] = useState([]);
  useEffect(() => {
    async function getImageData() {
      const { data } = await axios.get(
        `http://${config.API_URL}/gallery/${path}`
      );
      setImageData(data.data);
    }
    getImageData();
  }, [path]);
  return imageData;
}

function useTotalWidth(images, height, heightOffset) {
  const [totalWidth, setTotalWidth] = useState(0);
  height -= heightOffset;
  useEffect(() => {
    let width = 0;
    images.forEach((image) => (width += image.ratio * height));
    setTotalWidth(width);
  }, [images, height]);
  return totalWidth;
}

function useWindowDimensions() {
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  return [height, width];
}

function useMobileWidth(width) {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    if (width < 1000) setMobile(true);
    else setMobile(false);
  }, [width]);
  return mobile;
}

function Photos({ gallery }) {
  const heightOffset = 100;
  const images = useImageData(gallery);
  const [scroll, setScroll] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [height, width] = useWindowDimensions();
  const totalDocumentWidth = useTotalWidth(images, height, heightOffset);
  const mobile = useMobileWidth(width);

  useEffect(() => setScroll(0), [images]); // reset scroll when loading new gallery

  function handleClick(img) {
    // console.log("Image Clicked:", img);
    setActiveSlide(img);
  }

  function handleScroll(direction) {
    const scrollSweep = totalDocumentWidth * 0.12; // percentage of total doc width
    if (direction === "right") {
      let x = scroll - scrollSweep;
      if (Math.abs(x) > totalDocumentWidth - width)
        x = (totalDocumentWidth - width / 1.2) * -1;
      setScroll(x);
    } else if (direction === "left") {
      let x = scroll + scrollSweep;
      if (x > 0) x = 0;
      setScroll(x);
    }
  }

  return (
    <React.Fragment>
      <div className="slider">
        <div
          className="slider-wrapper"
          style={{
            transform: `translateX(${scroll}px)`,
            display: mobile ? "" : "flex",
          }}
        >
          {images.map((image) => (
            <Photo
              key={image.name}
              image={image}
              activeSlide={activeSlide}
              onClick={handleClick}
              height={height}
              width={width}
              mobile={mobile}
              offset={heightOffset}
              gallery={gallery}
            />
          ))}
        </div>
        {!mobile && (
          <div>
            <SlideButton
              onClick={handleScroll}
              position="left"
              height={height}
            />
            <SlideButton
              onClick={handleScroll}
              position="right"
              height={height}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Photos;
