import React from "react";

function CategoryList({ category, categories, setCategory }) {
  return (
    <ul className="list-group">
      {categories.map((cat) => (
        <li
          key={cat}
          style={{ cursor: "pointer", padding: "8px" }}
          onClick={() => setCategory(cat)}
          className={
            cat === category ? "list-group-item active" : "list-group-item"
          }
        >
          {cat.charAt(0).toUpperCase() + cat.slice(1)}
        </li>
      ))}
    </ul>
  );
}

export default CategoryList;
