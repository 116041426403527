import React, { useState, useEffect } from "react";
import axios from "axios";
import UploadForm from "./UploadForm";
import AdminPhoto from "./AdminPhoto";
import CategoryList from "./CategoryList";
import config from "../config";

function Admin(props) {
  const [file, setFile] = useState(null);
  const [del, setDel] = useState("");
  const [category, setCategory] = useState("people");
  const categories = ["people", "landscapes", "shop"];
  const [images, setImages] = useState([]);

  useEffect(() => {
    async function queryImages() {
      const { data } = await axios.get(
        `http://${config.API_URL}/gallery/${category}`
      );
      setImages(data.data);
    }
    queryImages();
  }, [category, file, del]);

  const handleDelete = async (image) => {
    setDel(image.name);
    // console.log("Delete:", image.name, category);
    await axios.post(`http://${config.API_URL}/delete`, {
      name: image.name,
      category: category,
    });
    setDel("");
  };

  return (
    <div
      className="container-fluid"
      style={{ marginTop: "20px", marginBottom: "50px" }}
    >
      <div className="row">
        <div className="col-3">
          <CategoryList
            category={category}
            categories={categories}
            setCategory={setCategory}
          />
        </div>
        <div className="col-7">
          <UploadForm category={category} file={file} setFile={setFile} />
        </div>
      </div>

      <div
        className="container-fluid"
        style={{ marginTop: "50px", display: "flex", flexWrap: "wrap" }}
      >
        {images.map((image) => (
          <AdminPhoto
            key={image.name}
            category={category}
            del={del}
            image={image}
            handleDelete={handleDelete}
          />
        ))}
      </div>
    </div>
  );
}

export default Admin;
