import React from "react";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";
import { useState } from "react";
import config from "../config";

function ContactForm(props) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [sendMessage, setSendMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSendMessage(true);
    const { status } = await axios.post(`http://${config.API_URL}/contact`, {
      name: name,
      email: email,
      message: message,
    });
    console.log(status);
    if (status === 200) {
      setName("");
      setEmail("");
      setMessage("");
      toast.success("Your message has been sent!");
      setSendMessage(false);
    } else toast.error("An error occured, please try again later.");
  };

  return (
    <form
      onSubmit={(e) => {
        handleSubmit(e);
      }}
    >
      <input
        type="text"
        placeholder="Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        required=" "
      />
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required=" "
      />
      <textarea
        placeholder="Message..."
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        required=" "
      ></textarea>
      <button type="submit" disabled={sendMessage} className="btn btn-dark">
        {sendMessage ? (
          <Spinner animation="border" variant="light" size="sm" />
        ) : (
          "Send"
        )}
      </button>
    </form>
  );
}

export default ContactForm;
