// import the library
import { library } from "@fortawesome/fontawesome-svg-core";

// import your icons
import {
  faHighlighter,
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import {
  faInstagramSquare,
  faFacebookSquare,
  faTwitterSquare,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faChevronLeft,
  faChevronRight,
  faHighlighter,
  faFacebookSquare,
  faInstagramSquare,
  faTwitterSquare
  // more icons go here
);
