import { useEffect } from "react";

function Logout({ setLoggedIn, history }) {
  useEffect(() => {
    localStorage.removeItem("token");
    setLoggedIn(false);
    history.push("/");
  }, [history, setLoggedIn]);

  return null;
}

export default Logout;
