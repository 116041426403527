import React from "react";
import ContactForm from "./ContactForm";
import SocialIcon from "./SocialIcon";

function Contact(props) {
  const socialLinks = [
    {
      url: "https://www.instagram.com/kristofflutchman/",
      icon: "instagram-square",
    },
    {
      url: "https://www.facebook.com/kristofflutchmanart",
      icon: "facebook-square",
    },
    {
      url: "https://twitter.com/krislutch",
      icon: "twitter-square",
    },
  ];

  return (
    <div className="container-fluid">
      <div className="jumbotron jumbotron-fluid">
        <div className="container">
          <h1 className="display-4">Get in touch</h1>
        </div>
      </div>
      <div className="container" style={{ marginBottom: "50px" }}>
        <div className="row">
          <div className="col main-body-left">
            <ul>
              {socialLinks.map((link, i) => (
                <SocialIcon key={i} url={link.url} icon={link.icon} />
              ))}
            </ul>
            <h2>Contact Me...</h2>
            <p>
              Been shooting since 2003. I've had a lot of practice and I am very
              passionate about photography, I believe this shows in my work.
              Please feel free to contact me if you are interested in a
              head-shot/portrait session. Sessions currently run $120.
            </p>
          </div>
          <div className="col main-body-right">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
